import React from 'react';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { PRODUCT } from '../constant/constant';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const OrderDetail = ({ orderInfo, show, toggleShow, formatDate }) => {
  let trackingUrl;
  if(orderInfo.order.trackingId){
    if(orderInfo.order.country === 'India'){
      trackingUrl = 'https://www.bluedart.com';
    }else{
      trackingUrl = 'https://www.indiapost.gov.in/VAS/TrackConsignment.aspx';
    }
  }
  const getActiveStep = () => {
    switch (orderInfo.order.status) {
      case 'paid':
        return 1;
      case 'crafting':
        return 2;
      case 'Shipped':
      case 'Delayed':
        return 3;
      case 'Delivered':
        return 4;
      default:
        return 0;
    }
  };

  const steps = [
    { name: 'Ordered', icon: 'fas fa-clipboard-list' },
    { name: 'Processing', icon: 'fas fa-box-open' },
    { name: 'Shipped', icon: 'fas fa-shipping-fast' },
    { name: 'Delivered', icon: 'fas fa-home' }
  ];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  };

  return (
    <Modal show={show} onHide={toggleShow} size="lg">
      <Modal.Header className="order_modal_head">
        <Modal.Title>Order Tracking</Modal.Title>
        <button type="button" className="btn-close position-absolute top-0 end-0 mt-2 me-2" aria-label="Close" onClick={toggleShow}></button>
      </Modal.Header>
      <div className="order_detail" style={{ width:"100%" }}>
        <div className="card card-stepper" style={{ borderRadius: '16px', border: 'none' }}>
          <div className="card-body">
            <div className="mb-4">
              <div className="order_info_date">
                <div>
                  <h5 className="order_cus_detail">Order ID:<br /> <span className="text-primary font-weight-bold">{orderInfo.order.order_id}</span></h5>
                </div>
                <div className="text-end">
                  <p className="order_cus_detail">Ordered Date: <br /><span>{formatDate(orderInfo.order.ordered_date)}</span></p>
                </div>
              </div>
              {orderInfo.order.status !== 'cancelled' && (
                <div id="progressbar-2" className="order_progress">
                  {steps.map((step, index) => (
                    <div key={index} className={`step${index + 1} ${index + 1 <= getActiveStep() ? 'active' : 'text-muted'} text-center`}>
                      <i className={`fa-3x ${step.icon} mb-3`}></i>
                      <p className="fw-bold mb-1">{step.name}</p>
                    </div>
                  ))}
                </div>
              )}
              <div className="order_info">
                <p className="order_cus_detail">Name: <span className="font-weight-bold">{orderInfo.order.customer_name}</span></p>
                <p className="order_cus_detail">Mobile: <span className="font-weight-bold">{orderInfo.order.mobile}</span></p>
                <p className="order_cus_detail order_cus_address">Address: <span className="font-weight-bold">{orderInfo.order.address}, {orderInfo.order.city}, {orderInfo.order.state}, {orderInfo.order.country}, {orderInfo.order.postalCode}</span></p>
                <p className="order_cus_detail">Amount: <span className="font-weight-bold"> {orderInfo.order.currency === 'INR' ? (
                                <>
                                ₹ {orderInfo.order.amount}
                                </>
                            ) : (
                                <>
                                $ {orderInfo.order.amount}
                                </>
                            )}
                  </span></p>
                <p className="order_cus_detail">Transaction ID: <span className="font-weight-bold">{orderInfo.order.payment_id}</span></p>
                {orderInfo.order.trackingId && (
                  <p className="order_cus_detail">
                      Tracking ID: 
                      <a href={trackingUrl} target="_blank" rel="noreferrer" className="font-weight-bold">
                        {orderInfo.order.trackingId}
                      </a>
                    </p>
                )}
              </div>
            </div>
            <div className='order_detail_products'>
              <p className='product_heading'>Products Ordered</p>
              <Slider {...sliderSettings}>
                {orderInfo.products.map((product, index) => (
                  <div key={index} className="single_order_product">
                    <img src={`${PRODUCT}${product.image1}`} alt="Product" className="img-fluid" />
                    <div className='single_order_product_info'>
                      <span>{product.name}</span>
                      <span className='single_order_product_quantity'>Quantity: {product.quantity}</span>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetail;
