import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import ProductBag from '../components/ProductBag';
import ProductBagDetails from '../components/ProductBagDetail';
import CountryModal from '../components/CountryModal';
import { useSelector, useDispatch } from 'react-redux'; 
import { removeFromCart,updateProductPrice } from '../redux/cartSlice'; 
import { toast } from 'react-toastify';
import { useAuth } from '../context/authContext';
import MetaTags from '../utils/MetaTags';
import axios from 'axios';

export default function Bag() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch(); 
  const [disablecheckout, setDisableCheckOut] = useState(false);
  const cartItems = useSelector(state => state.cart.items);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }else{
      checkProductQuantity();
    }
  }, [user]);

  const checkProductQuantity = async () => {
    try {
        const productsToReduce = cartItems.map(item => ({
            productId: item.id,
            quantity: item.quantity
        }));

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}check-products`, 
          {
            products: productsToReduce
          }, 
          {
            headers: {
              "Authorization": `Bearer ${user}`,
              "Content-Type": "application/json"
            },
            withCredentials: true 
          }
        );

        const results = response.data.results;
        results.forEach(result => {
            dispatch(updateProductPrice(result));
            if (result.message === 'Out of stock') {
                toast.warn(`${result.name} is out of stock, Removed from cart`, {
                    position: 'top-right'
                });
                dispatch(removeFromCart(result.productId));
            } else if(result.message === 'Quantity changed'){
              toast.warn(`${result.name} is short on stock, please update the quantity`, {
                  position: 'top-right'
              });
              setDisableCheckOut(true);
            }else {
              setDisableCheckOut(false);
            }
        });

    } catch (error) {
        console.error('Error reducing product quantity:', error);
        toast.error('Failed to check product quantities. Please try again later.', {
            position: 'top-right'
        });
    }
  };


  const handleRemoveFromCard = async (productId) => {
    const confirmRemove = window.confirm('Are you sure you want to remove this item from your cart?');
    if (!confirmRemove) {
      return;
    }

    try {
      dispatch(removeFromCart(productId)); 
      toast.success('Removing item from cart..', {
        position: 'top-right'
      });
      setTimeout(() => {
        window.location.reload();
    }, 1000); 
    } catch (error) {
      console.error("Error:", error);
      toast.error('Failed to remove item from cart. Please try again later.', {
        position: 'top-right'
      });
    }
  };

  return (
    <>
      <MetaTags 
        title="Shopping Cart - Wallfleur" 
        description="Review the items in your shopping cart before checkout."
        keywords="ecommerce, shopping cart, checkout"
        noIndex={true}
      />
      <Navbar/>
      <div className="wallfleur_row">
        <div className='bag-container'>
          <div className="bag-left">
            <h1 className='bag-heading'>Shopping Cart</h1>
            {cartItems.length >= 1 ? (
              <ProductBag cardData={cartItems} handleRemoveFromCard={handleRemoveFromCard} />
            ) : (
              <h3 className='bag-empty'>Your Cart is Empty</h3>
            )}
          </div>
          <div className='bag-right'>
            <ProductBagDetails cardData={cartItems} disablecheckout={disablecheckout} />
          </div>
        </div>
      </div>
        <Footer/>
      <CountryModal/>
    </>
  );
}
