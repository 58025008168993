import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import { Button, Container, Card, Form, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const { token } = useParams();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setErrors({});
    
    if (!oldPassword.trim() || !newPassword.trim()) {
      setErrors(prev => ({ ...prev, oldPassword: 'Old password is required', newPassword: 'New password is required' }));
      return;
    }
    if (newPassword.length < 8) {
      setErrors(prev => ({ ...prev, newPassword: 'Password must be at least 8 characters' }));
      return;
    }
    if (newPassword !== confirmNewPassword) {
      setErrors(prev => ({ ...prev, confirmNewPassword: 'Passwords do not match' }));
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}reset-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token, oldPassword, newPassword })
      });

      if (response.ok) {
        toast.success('Password reset successfully! You can now log in.', { position: 'top-right' });
        setMessage('Password reset successfully! You can now log in.');
      } else {
        const errorData = await response.json();
        setErrors({ token: errorData.message });
      }
    } catch (error) {
      console.error('Error:', error);
      setErrors({ token: 'An error occurred. Please try again later.' });
    }
    setIsLoading(false);
  };

  return (
    <>
    <Navbar />
    <Container className="auth-container">
      <div className="auth-form">
        <Card className="shadow">
          <Card.Body>
            <div className="mb-3 mt-md-4">
              <h2 className="fw-bold mb-2 text-uppercase">Reset Password</h2>
              {errors.token && <Alert variant="danger">{errors.token}</Alert>}
              {errors.oldPassword && <Alert variant="danger">{errors.oldPassword}</Alert>}
              {errors.newPassword && <Alert variant="danger">{errors.newPassword}</Alert>}
              {errors.confirmNewPassword && <Alert variant="danger">{errors.confirmNewPassword}</Alert>}
              {message && <Alert variant="success">{message}</Alert>}
              <Form onSubmit={handleResetPassword}>
                <Form.Group className="mb-4">
                  <Form.Label>Old Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your old password"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-4">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter new password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-5">
                  <Form.Label>Confirm New Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Confirm new password"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    required
                  />
                </Form.Group>
                <Button className="w-100" variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Resetting...' : 'Reset Password'}
                </Button>
              </Form>
            </div>
          </Card.Body>
        </Card>
      </div>
    </Container>
    </>

  );
};

export default ResetPassword;
