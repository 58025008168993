import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { DEFAULT_IMAGE, COMMON } from '../constant/constant';
import CountryContext from '../context/CountryContext';

const CountryModal = () => {
  const { updateCountry } = useContext(CountryContext);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const modalValue = Cookies.get('selectedCountry');
    if (!modalValue) {
      setTimeout(() => {
        setShowModal(true);
      }, 10000);
    }
  }, []);

  const handleButtonClick = async (value) => {
    Cookies.set('selectedCountry', value, { expires: 1 / 24 });
    await setInternationalSession();
    updateCountry(value);
    window.location.reload();
    setShowModal(false);
  };

  const setInternationalSession = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}set-international-session`, 
        {},
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  return (
    <div>
      {showModal && (
        <div className="countrymodal">
          <div className="modal-content">
            <p>Select Region/Currency</p>
            <div className='countrybtn'>
              <div className="country-option" onClick={() => handleButtonClick('India')}>
                <img className='lazyload' src= {`${DEFAULT_IMAGE}`} data-src={`${COMMON}flag.png`} alt="India Flag" style={{ height: "70px", width: "70px" }} />
                <p>India/IND(₹)</p>
              </div>
              <div className="country-option" onClick={() => handleButtonClick('Global')}>
                <img className='lazyload' src= {`${DEFAULT_IMAGE}`} data-src={`${COMMON}global.png`} alt="Globe" style={{ height: "70px", width: "70px" }} />
                <p>Global/USD($)</p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={showModal ? "backdrop blur" : "backdrop"} />
    </div>
  );
};

export default CountryModal;
