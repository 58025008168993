import React from 'react';
import { useNavigate } from "react-router-dom";
import { CATEGORY } from '../../constant/constant';

export default function ProductCard({ datas, contentType }) {
  const navigate = useNavigate();

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  return (
      <div className="product-card">
        {datas ? (
          datas.map((data, index) => (
            <div className="prod-card" onClick={() => handleRedirectUrlClick(`/${contentType}/${data.slug}`)}>
                <div className='prod-img'>
                  <img className="card-top" src={`${CATEGORY}${data.image}`} alt={`${data.name}`} />
                </div>
                  <div className="prod-card-body">
                    <p className="prod-name">{data.name}</p>
                  </div>
              </div>
          ))
        ) : (
          <p>No data available</p>
        )}
      </div>
  );
}
