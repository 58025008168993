import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, addToCart } from '../redux/cartSlice';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(() => {
    const storedUser = Cookies.get('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const cartItems = useSelector(state => state.cart.items);
  const dispatch = useDispatch();

  const login = async (userData) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}bag`, {
        headers: {
          "Authorization": `Bearer ${userData}`,
          "Content-Type": "application/json"
        }
      });

      const products = response.data.products;

      products.forEach(product => {
        dispatch(addToCart({ ...product, quantity: product.quantity }));
      });

      setUser(userData);
      Cookies.set('user', JSON.stringify(userData), { expires: 2 / 24 });

    } catch (error) {
      console.error("Error fetching cart data:", error);
    }
  };

  const logout = useCallback(async () => {
    if (!user) return;

    const products = cartItems.map(item => ({
      id: item.id,
      quantity: item.quantity
    }));

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}addtobag`, { products }, { 
        headers: {
          "Authorization": `Bearer ${user}`,
          "Content-Type": "application/json"
        }
      });
      dispatch(clearCart());
      Cookies.remove('user');
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  }, [user, cartItems, dispatch]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (user) {
        const decodedToken = jwtDecode(user);
        const currentTime = Date.now() / 1000; 

        if (decodedToken.exp < currentTime) {
          logout(); 
        }
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 60 * 1000); 

    return () => clearInterval(intervalId);
  }, [user, logout]); 

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
