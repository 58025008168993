import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal';
import { useCountry } from '../context/CountryContext';
import { DEFAULT_IMAGE,COMMON,PRODUCT } from '../constant/constant';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart } from '../redux/cartSlice';
import { toast } from 'react-toastify';
import { useAuth } from '../context/authContext';

export default function Card({ cardData, contentType }) {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const navigate = useNavigate();
  const { selectedCountry } = useCountry();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [queuedProduct, setQueuedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const cart = useSelector(state => state.cart.items);


  const handleCloseLoginModal = () => {
    setShowLoginModal(false);
  };

  const handleRedirectUrlClick = (url) => {
    if (url) {
      navigate(url);
    }
  };

  useEffect(() => {
    if (user && queuedProduct) {
      const existingProduct = cart.find(item => item.id === queuedProduct.id);
      const maxQuantity = queuedProduct.maxquantity;
      if (existingProduct) {
        const totalQuantity = existingProduct.quantity + 1;
        if (totalQuantity > maxQuantity) {
          toast.error(`Already reached maximum quantity of ${maxQuantity} ${queuedProduct.name}(s).`);
          setQueuedProduct(null);
          return;
        }else{
          setLoading(true);
          dispatch(addToCart({ ...queuedProduct, quantity: 1 }));
          toast.success(`${queuedProduct.name} added to cart!`);
          setQueuedProduct(null);
          setLoading(false);
        }
      }else{
        setLoading(true);
        dispatch(addToCart({ ...queuedProduct, quantity: 1 }));
        toast.success(`${queuedProduct.name} added to cart!`);
        setQueuedProduct(null);
        setLoading(false);
      }
    }
  }, [user, queuedProduct]);
  const handleAddToCartClick = (product) => {
    if (product.quantity === 0) {
      toast.error(`${product.name} not available.`);
      return;
    }
    const existingProduct = cart.find(item => item.id === product.id);
    const maxQuantity = product.maxquantity;
    if (existingProduct) {
      const totalQuantity = existingProduct.quantity + 1;
      if (totalQuantity > maxQuantity) {
        toast.error(`Already reached maximum quantity of ${maxQuantity} ${product.name}(s).`);
        return;
      }
    }
    if (user) {
      setLoading(true);
      dispatch(addToCart({ ...product, quantity: 1 }));
      toast.success(`${product.name} added to cart!`);
      setLoading(false);
    } else {
      setQueuedProduct(product);
      setShowLoginModal(true);
    }
  };

  return (
    <>
      <div className="product-card">
        {cardData.map((card) => (
          <div key={card._id} className="prod-card">
          {card.preorder === '1' && (
              <div className="preorder-badge">
                <img src={`${COMMON}preorder-badge.png`} alt="preorder-badge" />
              </div>
            )}
            <div className={`prod-img ${card.quantity <= 2 ? 'sold_out' : ''}`}>
                <img className="card-top lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${card.image1}`} alt={`Card ${card._id} cap`} onClick={() => handleRedirectUrlClick(`/${contentType}/${card.slug}`)} />
                <span style={{ display: card.quantity <= 2 ? 'block' : 'none' }}>Out of Stock</span>
            </div>
            <div className="prod-card-body">
              <div className="prod-content">
                <Link to={`/${contentType}/${card.slug}`} className="prod-name">{card.name}</Link>
                <p className="prod-price">{card.currency}{card.price}</p>
              </div>
              <div className='add-to-bag'>
                <img className='lazyload' src={`${DEFAULT_IMAGE}`} data-src={`${COMMON}add-to-cart.png`} alt="add-to-bag" onClick={() => handleAddToCartClick(card)} disabled={loading} />
              </div>
            </div>
          </div>
        ))}
      </div>
      {showLoginModal && <LoginModal onClose={handleCloseLoginModal} />}
    </>
  );
}
