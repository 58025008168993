import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Carousel from '../components/Carousel';
import CommonSlider from '../components/CommonSlider';
import Card from '../components/Card';
import CountryModal from '../components/CountryModal';
import BentoGrid from '../components/BentoGrid';
import { COMMON, DEFAULT_IMAGE } from '../constant/constant';
import { useCountry } from '../context/CountryContext';
import MetaTags from '../utils/MetaTags';
import axios from 'axios';

export default function Home() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const { selectedCountry,updateCountry } = useCountry();

  const questions = [
    {
      question: "Q. Do you ship internationally?",
      answer: "A. Yes, we do! We ship to most countries worldwide. Please bear in mind that the package may subject to import taxes, custom duties and fees on arrival, imposed by the destination country. These charges are the buyer's responsibility and not included with the shipping."
    },
    {
      question: "Q. What is the delivery time for international orders?",
      answer: "A. 3-6 weeks."
    },
    {
      question: "Q. What is the delivery time within India?",
      answer: "A. 3-6 business days."
    },
    {
      question: "Q. Do you accept custom orders?",
      answer: "A. Yes, but we rarely open custom slots. Follow us on Instagram @wallfleurthings to stay updated on when we accept custom orders. We will announce availability through our Instagram posts."
    },
    {
      question: "Q. What payment methods do you accept?",
      answer: "A. We accept Bank Transfer, UPI, and PayPal."
    },
    {
      question: "Q. What materials do you use to make your products?",
      answer: "A. We use clay, gypsum, acrylic paint, gloss varnish, and resin to craft our unique handmade items."
    },
    {
      question: "Q. What happens if my package is lost or delayed?",
      answer: "A. Once shipped, packages are beyond our control. We are not liable for lost or delayed packages. However, we'll do our best to assist you in resolving the issue."
    },
    {
      question: "Q. How long does it take to process my order?",
      answer: "A. For pre-orders, allow 3-4 weeks for processing. We will notify you once your order is ready for shipping."
    },
    {
      question: "Q. Which delivery services do you use?",
      answer: "A. For India: BlueDart, ExpressBe, Delhivery. For International: India Post."
    },
    {
      question: "Q. Do you offer Cash on Delivery (COD)?",
      answer: "A. No, we do not offer COD. Payment must be made online at the time of ordering."
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}homepage`, {
          withCredentials: true,
        });
        setData(response.data);
        updateCountry(response.data.currency);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className='catloader'>
        <img src={`${COMMON}wallfleur.loading.gif`} alt='loader' />
      </div>
    );
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Home Page - Wallfleur",
    "description": "Shop the latest products from a small artist with big dreams!",
    "url": "https://www.wallfleurthings.com",
    "mainEntity": {
      "@type": "Organization",
      "name": "Wallfleur",
      "url": "https://www.wallfleurthings.com",
      "logo": "https://www.wallfleurthings.com/logo.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "contactType": "Customer Service",
        "telephone": "+917005199452",
        "email": "wallfleurthings@gmail.com"
      }
    }
  };

  return (
    <div>
      <MetaTags
        title="Home Page - Wallfleur"
        description="Shop the latest products from a small artist with big dreams!"
        keywords="ecommerce, handmade products, small business"
        schema={schema}
      />
      <Navbar />
      {data.advertisement.length > 0 && (
        <Carousel slides={data.advertisement} />
      )}
      <div className="wallfleur_row">
      {data.PreOrderproducts.length >= 1 && (
        <div className='home-preorder'>
          <div className='category-heading'>
            <h2 className='heading-text'>Preorder update: Jan 10, 2025 at 12:00 pm IST</h2>
            <p>Small-batch, Handmade clock, Made in Nagaland, India. Limited Slots, Worldwide Shipping.</p>
          </div>
          <CommonSlider datas={data.PreOrderproducts} countryCurrency={selectedCountry} contentType="product" />
        </div>
      )}
      </div>
      {data.product.length >= 1 && (
        <div className='featured-products'>
          <div>
            <h2 className='heading-text'>Featured Products</h2>
          </div>
          
          <Card cardData={data.product} contentType="product" />
        </div>
      )}
      <div className="wallfleur_row">
      {data.category.length >= 1 && (
        <div className='home-category'>
          <div className='category-heading'>
              <h2 className='heading-text'>Shop by Category</h2>
            </div>
          <BentoGrid datas={data.category} contentType="category" />
        </div>
      )}
        <div className="thank-you col-md-12" style={{ "textAlign": "center" }}>
          <p>
            ✧Thanks for stopping by and supporting our work!✧
          </p>
        </div>
        <div className="about-container">
          <div className="about-content">
              <img src={`${DEFAULT_IMAGE}`} data-src={`${COMMON}about_us.jpg`} alt="Wallfleurthings" className="about-image lazyload" />
              <div className="about-text">
                  <h1>About Us</h1>
                  <p>
                  Welcome to Wallfleurthings, our cozy little creative corner. We're a team of three sisters with a shared love for art and creativity. Operating as a small home studio, everything is thoughtfully designed and handmade with love and care here in Nagaland, India.
                  </p>
                  <p>
                  We hope our creations bring you joy and add a special touch to your space. Thank you for stopping by and supporting our work!
                  </p>
              </div>
          </div>
        </div>
        <div className='faq-section'>
          <div className="qa-section col-md-7">
              {questions.slice(0, showMore ? questions.length : 4).map((qa, index) => (
                <div key={index} className="accordion-item">
                  <div className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {qa.question}
                    </button>
                  </div>
                  <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`}>
                    <div className="accordion-body">
                      {qa.answer}
                    </div>
                  </div>
                </div>
              ))}
              {!showMore && (
                <button className="btn qa_seemore" onClick={() => setShowMore(true)}>
                  See More
                </button>
              )}
          </div>
          <div className='heading-text'>
            <h2>FAQ: Everything You Need to Know</h2>
          </div>
        </div>
      </div>
      <Footer />
      <CountryModal />
    </div>
  );
}
