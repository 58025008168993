import React from 'react';
import { DEFAULT_IMAGE,PRODUCT } from '../../constant/constant';

export default function ProductDetails({ cardData,countryCurrency }) {    
    let deliveryCharge;
    const currency = countryCurrency === 'India' ? '₹' : '$';
    const productTotal = cardData.reduce((total, card) => total + card.price * card.quantity, 0);
    if(countryCurrency === 'India'){
        deliveryCharge = productTotal >= 4000 ? 350 : 150;
    }else{
        deliveryCharge = productTotal >= 130 ? 35 : 20;
    }
    const totalAmount = productTotal + deliveryCharge ;

    return (
        <div className="product-details">
            <h3>Your Cart</h3>
            <div className="cart-items">
                {cardData.map((card) => (
                    <div key={card.id} className="checkout-card">
                        <div className="checkout-card-body">
                            <img className="checkout-prod-img lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${card.image1}`} alt={`Card ${card.id} cap`} />
                            <div className="checkout-content">
                                <p className="checkout-name">{card.name}</p>
                                <p className="checkout-price">Price: {currency}{card.price.toFixed(2)}</p>
                                <p className="checkout-quantity">Quantity: {card.quantity}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="checkout-summary">
                <div className="checkout-summary-item">
                    <span>SubTotal:</span>
                    <span>{currency}{productTotal.toFixed(2)}</span>
                </div>
                <div className="checkout-summary-item">
                    <span>Delivery Charge:</span>
                    <span>{currency}{deliveryCharge.toFixed(2)}</span>
                </div>
                <div className="checkout-summary-item total">
                    <span>Total:</span>
                    <span>{currency}{totalAmount.toFixed(2)}</span>
                </div>
            </div>
        </div>
    );
}
