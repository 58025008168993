import React from 'react';
import { Link } from 'react-router-dom';
import { useCountry } from '../context/CountryContext';
import { DEFAULT_IMAGE,PRODUCT } from '../constant/constant';


export default function ProductBag({cardData,handleRemoveFromCard}) {
    const { selectedCountry } = useCountry();
    const url = 'product';
    return (
        <>
            <div className='bag-prod-container'>
                {cardData.map((card) => (
                    <div key={card._id} className="bag-prod-card">
                        <div className="prod-img">
                            <img className="card-top lazyload" src={`${DEFAULT_IMAGE}`} data-src={`${PRODUCT}${card.image1}`} alt={`Card ${card._id} cap`} />
                        </div>
                        <div className="prod-card-body">
                            <Link to={`/${url}/${card.slug}`}>
                                <div className="prod-content">
                                    <p className="prod-name">{card.name}</p>
                                    <p className="prod-price">Quantity: {card.quantity}</p>
                                </div>
                            </Link>
                            <p className="prod-price">{selectedCountry === 'India' ? `₹ ${card.price}` : `$ ${card.price}`}</p>
                        </div>
                        <div className="prod-btn">
                            <button className="removefromcard" onClick={() => handleRemoveFromCard(card.id)}>X</button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}
