import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

const CountryContext = createContext();

export const CountryProvider = ({ children }) => {
  const initialCountry = Cookies.get('selectedCountry') || 'India'; 
  const [selectedCountry, setSelectedCountry] = useState(initialCountry);

  const updateCountry = (country) => {
    setSelectedCountry(country);
    Cookies.set('selectedCountry', country, { expires: 1 / 24 });
  };

  return (
    <CountryContext.Provider value={{ selectedCountry, updateCountry }}>
      {children}
    </CountryContext.Provider>
  );
};

export const useCountry = () => {
  return useContext(CountryContext);
};

export default CountryContext;
