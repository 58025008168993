import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ADVERTISEMENT } from '../constant/constant';


export default function Carousel({slides}) {

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    autoplay:true,
    autoplaySpeed: 2000,
  };

  const handleRedirectUrlClick = (url) => {
    if (url) {
      window.location.href = url;
    }
  };


   return (
    <div className='home_carousel'>
      {slides.length > 1 ? (
        <Slider {...settings}>
          {slides.map((data, index) => (
            <div key={index}>
              <div className="wall-banner slide-card" onClick={() => handleRedirectUrlClick(data.banner_url)}>
                <img className="card-img-top" src={`${ADVERTISEMENT}${data.banner_image}`} alt="Card cap"/>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffff" fillOpacity="1" d="M0,160L34.3,149.3C68.6,139,137,117,206,133.3C274.3,149,343,203,411,224C480,245,549,235,617,208C685.7,181,754,139,823,106.7C891.4,75,960,53,1029,80C1097.1,107,1166,181,1234,197.3C1302.9,213,1371,171,1406,149.3L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="wall-banner slide-card" onClick={() => handleRedirectUrlClick(slides[0].banner_url)}>
          <img className="card-img-top" src={`${ADVERTISEMENT}${slides[0].banner_image}`} alt="banner" />
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffff" fillOpacity="1" d="M0,160L34.3,149.3C68.6,139,137,117,206,133.3C274.3,149,343,203,411,224C480,245,549,235,617,208C685.7,181,754,139,823,106.7C891.4,75,960,53,1029,80C1097.1,107,1166,181,1234,197.3C1302.9,213,1371,171,1406,149.3L1440,128L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
        </div>
      )}
    </div>
  );
  
}
