import React, { useState, useEffect,useRef } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/authContext';
import { toast } from 'react-toastify';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const MainProfile = () => {
    const { user } = useAuth();
    const [profile, setProfile] = useState({
        name: '',
        email: '',
        number: '',
        dialcode:'',
        address: '',
        country: '',
        state: '',
        city: '',
        pinCode: '',
        status: true,
    });
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [postalPattern, setPostalPattern] = useState('');
    const [errors, setErrors] = useState({});
    const itiRef = useRef(null);
    const inputRef = useRef(null);
    const [countryLoading, setCountryLoading] = useState(true);

    useEffect(() => {
        fetchCountries();
    }, []);

    useEffect(() => {
        if (inputRef.current) {
          itiRef.current = intlTelInput(inputRef.current, {
            initialCountry: 'in',
            preferredCountries: ['in', 'us'],
            separateDialCode: true,
            utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
          });
          if(profile.dialcode){
            itiRef.current.setNumber(profile.dialcode+profile.number);
          }
        }
        return () => {
          if (itiRef.current) {
            itiRef.current.destroy();
          }
        };
      }, [inputRef.current]);

    useEffect(() => {
        const fetchUserData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}profile`, {
                    headers: {
                        "Authorization": `Bearer ${user}`,
                        "Content-Type": "application/json"
                    }
                });
        
                const data = response.data;
        
                setProfile({
                    name: data.name,
                    email: data.email,
                    number: data.phone,
                    dialcode: data.dialcode,
                    address: data.address_1,
                    country: data.country,
                    state: data.state,
                    city: data.city,
                    pinCode: data.pinCode,
                    status: data.status === 1,
                });
            } catch (error) {
                console.error("Error:", error);
                toast.error('Failed to fetch user data', { position: 'top-right' });
            } finally {
                setLoading(false);
            }
        };
        

        fetchUserData();
    }, [user]);

    const fetchCountries = async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            const sortedCountries = response.data.data
                .map(country => country.country)
                .sort((a, b) => a.localeCompare(b));
            setCountries(sortedCountries);
        } catch (error) {
            console.error('Error fetching countries from CountriesNow', error);
        } finally {
            setCountryLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfile(prevProfile => ({
            ...prevProfile,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};

        const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
        const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
        const iti = itiRef.current;

        if (!profile.number) {
            errors.number = 'Phone Number is required';
        } else if (!iti.isValidNumber()) {
            errors.number = 'Invalid phone number';
        } else {
            profile.dialcode = dialCode;
        }

        if (!profile.name) errors.name = 'Username is required';
        if (!profile.address) errors.address = 'Address is required';
        if (!profile.country) errors.country = 'Country is required';
        if (!profile.state) errors.state = 'State is required';
        if (!profile.city) errors.city = 'City is required';
        if (!profile.pinCode) errors.pinCode = 'PinCode is required';

        setErrors(errors);

        if (Object.keys(errors).length > 0) {
            return; 
        }
        setLoading(true);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}updateCustomer`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${user}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: profile.name,
                    phone: profile.number,
                    address_1: profile.address,
                    dialcode: profile.dialcode,
                    country: profile.country,
                    state: profile.state,
                    city: profile.city,
                    pinCode: profile.pinCode,
                    status: profile.status ? 1 : 0
                })
            });

            if (response.ok) {
                const data = await response.json();
                toast.success(data.message, { position: 'top-right' });
            } else {
                console.error("Error updating profile:", response);
                toast.error('Failed to update profile', { position: 'top-right' });
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error('An error occurred while updating profile', { position: 'top-right' });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="profile-container">
            {loading && <p>Loading...</p>}
            {!loading && (
                <div className="profile-details">
                    <form onSubmit={handleSubmit}>
                        <div className="profile-item">
                            <input
                                className="profile-value"
                                name="name"
                                placeholder='Username'
                                value={profile.name}
                                onChange={handleChange}
                            />
                            {errors.name && <div className="text-danger">{errors.name}</div>}
                        </div>
                        <div className="profile-item">
                            <input
                                className="profile-value"
                                name="email"
                                style={{ cursor: 'not-allowed', backgroundColor:'#f4f4f4', color:'grey' }}
                                placeholder='Email'
                                value={profile.email}
                                readOnly
                            />
                        </div>
                        <div className="profile-item">
                            <input
                                type="tel"
                                className="profile-value"
                                name="number"
                                ref={inputRef}
                                id="phoneNumber"
                                value={profile.number}
                                onChange={handleChange}
                            />
                            {errors.number && <div className="text-danger">{errors.number}</div>}
                        </div>
                        <div className="profile-item">
                            <textarea
                                className="profile-value"
                                name="address"
                                placeholder='Address'
                                value={profile.address}
                                onChange={handleChange}
                            />
                            {errors.address && <div className="text-danger">{errors.address}</div>}
                        </div>
                        <div className="profile-row">
                            <div className="profile-item profile-inline">
                            {countryLoading ? (
                                <p>Loading countries...</p>
                            ) : (
                                <select
                                    className="profile-value"
                                    name="country"
                                    value={profile.country}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Country</option>
                                    {countries.map((country) => (
                                        <option key={country} value={country}>{country}</option>
                                    ))}
                                </select>
                                   )}
                                {errors.country && <div className="text-danger">{errors.country}</div>}
                            </div>
                            <div className="profile-item profile-inline">
                                <input
                                    className="profile-value"
                                    name="state"
                                    placeholder='state'
                                    value={profile.state}
                                    onChange={handleChange}
                                />
                                {errors.state && <div className="text-danger">{errors.state}</div>}
                            </div>
                        </div>
                        <div className="profile-row">
                            <div className="profile-item profile-inline">
                                <input
                                    className="profile-value"
                                    name="city"
                                    placeholder='city'
                                    value={profile.city}
                                    onChange={handleChange}
                                />
                                {errors.city && <div className="text-danger">{errors.city}</div>}
                            </div>
                            <div className="profile-item profile-inline">
                                <input
                                    className="profile-value"
                                    name="pinCode"
                                    placeholder='Zip/Postal Code'
                                    value={profile.pinCode}
                                    onChange={handleChange}
                                    pattern={postalPattern ? postalPattern.source : undefined}
                                />
                                {errors.pinCode && <div className="text-danger">{errors.pinCode}</div>}
                            </div>
                        </div>
                        <div className="profile-item">
                            <button className='profile-update-btn' type="submit" disabled={loading}>
                                {loading ? 'Updating...' : 'Update Profile'}
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default MainProfile;
