import React, { useState, useEffect, useContext } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { DEFAULT_IMAGE, COMMON } from '../constant/constant';
import CountryContext from '../context/CountryContext';

const CurrencyChange = () => {
  const { selectedCountry, updateCountry } = useContext(CountryContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isProductPage, setIsProductPage] = useState(false);

  useEffect(() => {
    const segments = window.location.pathname.split('/');
    if (segments.includes('product')) {
      setIsProductPage(true);
    }
  }, []);

  const handleButtonClick = async (value) => {
    let getcurrency = Cookies.get('selectedCountry');

    if (value !== getcurrency) {
      Cookies.set('selectedCountry', value, { expires: 1 / 24 });
      await setInternationalSession(value === 'NO' ? 0 : 1);
      updateCountry(value === 'NO' ? 'Global' : 'India');
      setDropdownVisible(false);
      window.location.reload();
    }
  };

  const setInternationalSession = async (flag) => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}set-international-session`,
        { flag },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  return (
    <div className={isProductPage ? 'currency-selector product-page' : 'currency-selector'}>
      <div
        className="selected-country"
        onClick={() => setDropdownVisible((prev) => !prev)}
      >
        {selectedCountry === 'India' ? 'India/IND(₹)' : 'Global/USD($)'}
      </div>

      {dropdownVisible && (
        <div className="dropdown">
          <div
            className="dropdown-option"
            onClick={() => handleButtonClick('YES')}
          >
            <img className='lazyload' src={`${DEFAULT_IMAGE}`} data-src={`${COMMON}flag.png`} alt="India Flag" />
            <p>India/IND(₹)</p>
          </div>
          <div
            className="dropdown-option"
            onClick={() => handleButtonClick('NO')}
          >
            <img className='lazyload' src={`${DEFAULT_IMAGE}`} data-src={`${COMMON}global.png`} alt="Globe" />
            <p>Global/USD($)</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurrencyChange;
