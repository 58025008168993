import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { ColorRing } from "react-loader-spinner";
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import { useCountry } from '../../context/CountryContext';

export default function ShippingDetails({ userData, handleInputChange, handleContinue }) {
    const { selectedCountry } = useCountry();
    const [errors, setErrors] = useState({});
    const [countries, setCountries] = useState([]);
    const [postalPattern, setPostalPattern] = useState('');
    const [loading, setLoading] = useState(false);
    const itiRef = useRef(null);
    const inputRef = useRef(null);
    const [countryLoading, setCountryLoading] = useState(true);


    useEffect(() => {
        fetchCountries();
        if (inputRef.current) {
            itiRef.current = intlTelInput(inputRef.current, {
              initialCountry: 'in',
              preferredCountries: ['in', 'us'],
              separateDialCode: true,
              utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
            });
          }

        if (selectedCountry === 'India') {
            handleInputChange({ target: { name: 'country', value: 'India' } });
            setPostalPattern(/^[1-9][0-9]{5}$/); 
        }
    }, []);

    const fetchCountries = async () => {
        try {
            const response = await axios.get('https://countriesnow.space/api/v0.1/countries');
            const sortedCountries = response.data.data
                .map(country => country.country)
                .sort((a, b) => a.localeCompare(b));
            setCountries(sortedCountries);
        } catch (error) {
            console.error('Error fetching countries from CountriesNow', error);
        } finally {
            setCountryLoading(false);
        }
    }; 
    

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
    
        if (!userData.name || !userData.name.trim()) {
            newErrors.name = 'Name is required';
            isValid = false;
        }
        const dialCodeElement = document.getElementsByClassName('iti__selected-dial-code')[0];
        const dialCode = dialCodeElement ? dialCodeElement.innerText : '+91';
        const iti = itiRef.current;

        if (!userData.mobile) {
            newErrors.name = 'Phone no. is required';
        } else if (!iti.isValidNumber()) {
            newErrors.name = 'Invalid Phone no. format';
        } else {
            userData.dialcode = dialCode;
        }
        
        if (!userData.address || !userData.address.trim()) {
            newErrors.address = 'Address Line 1 is required';
            isValid = false;
        }
        if (!userData.country || !userData.country.trim()) {
            newErrors.country = 'Country is required';
            isValid = false;
        }
        if (!userData.state || !userData.state.trim()) {
            newErrors.state = 'State is required';
            isValid = false;
        }
        if (!userData.city || !userData.city.trim()) {
            newErrors.city = 'City is required';
            isValid = false;
        }
        if (!userData.postalCode) {
            newErrors.postalCode = 'Postal Code is required';
            isValid = false;
        } else if (postalPattern && !postalPattern.test(userData.postalCode)) {
            newErrors.postalCode = 'Postal Code is invalid';
            isValid = false;
        }
    
        setErrors(newErrors);
        setLoading(false); 
        return isValid;
    };
    

    const handleSubmit = async (e) => {
        setLoading(true); 
        e.preventDefault();
        if (validateForm()) {
            try {
                handleContinue();
                setLoading(false); 
            } catch (error) {
                setErrors({ postalCode: 'Postal Code does not match the selected city, state, and country' });
                setLoading(false); 
            }
        }
    };

    const handleCountryChange = (e) => {
        const country = e.target.value;
        handleInputChange(e);

        if (country === 'India') {
            setPostalPattern(/^[1-9][0-9]{5}$/);
        } else {
            setPostalPattern(null);
        }
    };

    return (
        <div className="shipping-details">
            <h3>Shipping Details</h3>
            <form onSubmit={handleSubmit}>
                <div className='form-row'>
                <div className="form-group">
                    <input
                        type="text"
                        name="name"
                        placeholder='Enter Full name*'
                        value={userData.name || ''}
                        onChange={handleInputChange}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                </div>
                <div className="form-group">
                    <input
                        type="tel"
                        ref={inputRef}
                        name="mobile"
                        value={userData.mobile || ''}
                        onChange={handleInputChange}
                    />
                    {errors.mobile && <div className="error">{errors.mobile}</div>}
                </div>
                </div>
                <div className="form-group">
                    <input
                        type="text"
                        name="address"
                        placeholder='Address*'
                        value={userData.address || ''}
                        onChange={handleInputChange}
                    />
                    {errors.address && <div className="error">{errors.address}</div>}
                </div>
                <div className="form-row">
                    <div className="form-group">
                        {countryLoading ? (
                                <p>Loading countries...</p>
                            ) : (
                        <select name="country" value={userData.country} onChange={handleCountryChange} disabled={selectedCountry === 'India'} className={selectedCountry === 'India' ? 'select-disabled' : 'select-enabled'} >
                            <option value="">Select Country*</option>
                            {countries.map((country) => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </select>
                        )}
                        {errors.country && <div className="error">{errors.country}</div>}
                    </div>
                    <div className="form-group">
                    <input
                        type="text"
                        name="state"
                        placeholder='Enter State*'
                        value={userData.state || ''}
                        onChange={handleInputChange}
                    />
                        {errors.state && <div className="error">{errors.state}</div>}
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group">
                    <input
                        type="text"
                        name="city"
                        placeholder='Enter City*'
                        value={userData.city || ''}
                        onChange={handleInputChange}
                    />
                        {errors.city && <div className="error">{errors.city}</div>}
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            name="postalCode"
                            placeholder='Enter Zip/Postal Code*'
                            value={userData.postalCode || ''}
                            onChange={handleInputChange}
                        />
                        {errors.postalCode && <div className="error">{errors.postalCode}</div>}
                    </div>
                </div>
                <div className='checkout-submit-btn'>
                <button type="submit" disabled={loading}>                    
                {loading ?
                    <div className="loading-animation">
                        <ColorRing
                            type="TailSpin"
                            color="#00BFFF"
                            height={30}
                            width={30}
                        />
                        {'Please wait...'}
                    </div> : 'Continue'}</button>
                </div>
            </form>
        </div>
    );
}
